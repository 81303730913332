

























































































import {Component, Vue} from "vue-property-decorator";
import DocumentRow from "../widgets/DocumentRow.vue";
import WaitModal from "../widgets/modals/WaitModal.vue";
import YesNoModal from "@shared_vue/components/YesNoModal.vue";
import {vxm} from "@/store";

import vueDropzone from "@shared_vue/components/dropzone/vuedz"
import Axios from "axios";
import {DocumentDTO} from "@shared_vue/openapi/myvaultapi";
@Component({
  components: {DocumentRow, WaitModal, YesNoModal, vueDropzone},
})

export default class MyDocumentsPage extends Vue {
  private ui = vxm.ui;
  private auth = vxm.auth;
  private showWait: boolean = false;
  private showYesNoDelete: boolean = false;
  private tempId=0;
  private newName : string = ''
  private newCat: any = 0;
  private newFile: any ="";
  private showNewDocument1: boolean = false;
  private showNewDocument2: boolean = false;
  private newDescription: string = '';
  private showEditModal: boolean = false;
  private showSuccessModal: boolean = false;
  private tempDTO: DocumentDTO = <DocumentDTO>{};
  private catoptions = [
    {value:1, label:'Category 1'}
  ]

  private submitNew(){
    console.log('saving...')
    //add waiting modal


  }

  handleFileUpload(filez: any, eo: any){
    console.log('handlefileupload')
    this.newFile=filez[0];
  }

  public newModal1(){
    this.showNewDocument1=true;
  }

  public showSuccess(){
    this.showSuccessModal=true;
  }
  private submitUpdate(){

  }

  get CatName(){
    return this.catoptions.find(a=>a.value==this.newCat)?.label??''
  }

  private deleteConfirm(){
    //go via API

  }

  private next1(){
    // this.showNewDocument1=false;
    // this.showNewDocument2=true;
    console.log("next1")
    let formData = new FormData();
    formData.append('file', this.newFile);
    formData.append('description', this.newDescription)
    const outerthis = this;
    //move to service
    Axios.post(process.env.VUE_APP_MYVAULT_API_URL + '/file/NewDocument', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          console.log(response.status);
          if (response.status==200){
            outerthis.cancel();
            outerthis.showSuccess();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  private cancel(){
    this.showNewDocument1=false;
    this.showNewDocument2=false;
    this.newDescription='';
    this.newName='';
    this.newCat=null;
    this.showSuccessModal=false;
  }
  private myParamName() {
    return "files";
  }
  private dropzoneOptions= {
    url: process.env.VUE_APP_MYVAULT_API_URL + '/file/UploadDirect',
    paramName: this.myParamName,
    uploadMultiple: true,
    thumbnailWidth: 150,
    maxFilesize: 0.5,
    headers: {'Authorization': `Bearer ${this.auth.idToken}`}
  }
}
