










































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import {DocumentDTO} from "@shared_vue/openapi/myvaultapi";



@Component
export default class DocumentRow extends Vue {

  @Prop() readonly document: DocumentDTO | undefined
  @Prop() readonly clickFunc: Function | undefined
  @Prop() readonly delFunc: Function | undefined
  private ui = vxm.ui;


}
