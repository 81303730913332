


















import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';


@Component
export default class YesNoModal extends Vue {
  @Prop(String) readonly title: string | undefined;
  @Prop(String) readonly message: string | undefined;
  @Prop(String) readonly yesText: string | undefined;
  @Prop(String) readonly noText: string | undefined;
  @Prop(Function) readonly yesFunc: Function | undefined;
  @Prop(Function) readonly noFunc: Function | undefined;
  @PropSync('showvar', { type: Boolean }) syncedShow!: boolean;



  private dismiss(){
    this.syncedShow=false;

  }

  private onYes(){
    this.dismiss();
    if (this.yesFunc) {
      this.yesFunc();
    }
  }

  private onNo(){
    this.dismiss();
    if (this.noFunc) {
      this.noFunc();
    }
  }

}
